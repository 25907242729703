import { Component, Inject, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { User } from '../_models/user';
import { environment } from '../../environments/environment';
import { AdminService } from '../_services/admin.service';
import { PhonePipe } from '../_shared/pipes/phone';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  manualReferrerForm: FormGroup;
  isSubmitted = false;
  isEditing = false;

  constructor(private http: HttpClient, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: User, public service: AdminService) {
    if (data.email) {
      this.isEditing = true;
    }

    if (data.phone) {
      data.phone = new PhonePipe().transform(data.phone, null);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSubmitted = true;
    if (this.manualReferrerForm.invalid) {
      return;
    }

    if (this.isEditing && this.manualReferrerForm.value.id) {
      this.service.editReferrer(this.manualReferrerForm.value).subscribe(() => this.dialogRef.close());
    } else {
      this.service.addReferrer(this.manualReferrerForm.value).subscribe(() => this.dialogRef.close());
    }
  }

  ngOnInit() {
    let first_name = new FormControl(this.data.first_name, Validators.required);
    let last_name = new FormControl(this.data.last_name, Validators.required);
    let email = new FormControl(this.data.email, [Validators.required, CustomValidators.email]);
    let confirm_email = new FormControl(this.data.email, [Validators.required, CustomValidators.equalTo(email)]);
    let phone = new FormControl(this.data.phone, Validators.required);
    let id = new FormControl(this.data.id);

    this.manualReferrerForm = new FormGroup({
      first_name: first_name,
      last_name: last_name,
      email: email,
      confirm_email: confirm_email,
      phone: phone,
      id: id,
    });
  }
  get formControls() { return this.manualReferrerForm.controls; }
}
