import { AdminService } from '../_services/admin.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthService } from '../_authentication/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders,  HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalComponent } from '../modal/modal.component';
import { ModalPayMemberComponent } from '../modal-pay-member/modal-pay-member.component';
import { ModalChangePasswordComponent } from '../modal-change-password/modal-change-password.component';
import { ModalChangeEmailComponent } from '../modal-change-email/modal-change-email.component';
import { ModalAddAdminComponent } from '../modal-add-admin/modal-add-admin.component';
import { Observable, from } from 'rxjs';
import { Pagination } from '../_models/pagination';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../_models/user';
import { Admin } from '../_models/admin';
import { Profile } from '../_models/profile';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Component({
  selector: 'app-admin-view-admins',
  templateUrl: './admin-view-admins.component.html',
  styleUrls: ['./admin-view-admins.component.scss']
})
export class AdminViewAdminsComponent implements OnInit {
  public users: Array<User> = [];
  public referrals: Array<User> = [];
  admin: Admin;
  user: User;
  cookieValue = '';
  confirm_email: string;
  email: string;
  first_name: string;
  friend_code: string;
  last_name: string;
  password: string;
  phone: string;
  adminLevel = '';
  showAdminButton = false;

  searchText: string;
  searchTextChanged: Subject<string> = new Subject<string>();

  public pagination: Pagination = {
    count: 0,
    currentPage: 1,
    perPage: 10,
    pages: 0,
    perPageOptions: [10, 25, 50],
  };

  public apiUrl = environment.apiUrl;

  constructor(private authService: AuthService, public dialog: MatDialog, private http: HttpClient, private router: Router, public service: AdminService, private cookieService: CookieService ) {
    this.auth();
    this.cookieValue = this.cookieService.get('token');

    this.showAdmin();

    this.searchTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(model => {
        this.searchText = model.trim();
        this.fetchUsers();
      });
  }

  showAdmin() {
    this.adminLevel = this.cookieService.get('adminLevel');
    if (this.adminLevel === '0') {
      this.showAdminButton = true;
    } else {
      this.showAdminButton = false;
    }
  }

  auth() {
    this.http.get(environment.apiUrl + '/api/user/session').subscribe((data: any) => {
        if (data.success) {

        } else {
          this.router.navigateByUrl('/login');
        }
      });
  }

  fetchUsers() {
    this.users = [];
    this.service.getAdmins(this.pagination, this.searchText).subscribe(result => {
      this.users = result.data;
      this.parsePagination(result.meta);
    });
    // this.service.getNewestUnpaidReferrals().subscribe(result=>{this.referrals=result;})
  }

  generateQRCode(user) {
    this.router.navigateByUrl('/print/' + user.user_code);
  }

  openDialog(user): void {
    const data = user || {
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      confirm_email: this.confirm_email,
      phone: this.phone,
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data
    }).afterClosed()
    .subscribe( data => this.fetchUsers());
  }

  openAddAdminDialog(admin): void {
      const data = admin || {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password
      };
      const dialogRef = this.dialog.open(ModalAddAdminComponent, {
        data
      }).afterClosed()
      .subscribe( data => this.fetchUsers());
  }

  openPayDialog(user): void {
    const dialogRef = this.dialog.open(ModalPayMemberComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.payMember(user);
      }
    });
  }

  openPasswordDialog(user): void {
    const dialogRef = this.dialog.open(ModalChangePasswordComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.password = result.password;
      if (result) {
        this.updatePassword(user, this.password);
      }
    });
  }

  openEmailDialog(user): void {
    const dialogRef = this.dialog.open(ModalChangeEmailComponent);

    dialogRef.afterClosed().subscribe(result => {
      this.email = result.email;
      if (result) {
        this.updateEmail(user, this.email);
      }
    });
  }

  openArchiveDialog(user): void {
    const dialogRef = this.dialog.open(ModalPayMemberComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.archiveAdmin(user);
      }
    });
  }

  ngOnInit() {
    this.fetchUsers();
  }

  payMember(user) {
    this.service.payMember(user).subscribe(result => this.fetchUsers());
  }

  updatePassword(user, input) {
    this.http.put<any>(environment.apiUrl + '/api/user/' + user.id, {password: input}).subscribe();
  }

  updateEmail(user, input) {
    this.http.put<any>(environment.apiUrl + '/api/user/' + user.id, {email: input}).subscribe(data => this.fetchUsers());
  }

  archiveAdmin(user) {
    this.service.deleteAdmin(user).subscribe(result => this.fetchUsers());
  }

  logout() {
    this.cookieService.delete('token');
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  parsePagination(meta) {
    this.pagination.currentPage = meta._page;
    this.pagination.count = meta._count;
    this.pagination.perPage = meta._perPage;
    this.pagination.pages = meta._pages;
  }

  get paginationDidChange() {
    return this.fetchUsers.bind(this);
  }

  searchChanged(text: string) {
    this.searchTextChanged.next(text);
  }

  viewArchivedAdmins() {
    this.router.navigateByUrl('/archived-admins');
  }

  return() {
    this.router.navigateByUrl('/admin');
  }

  // allUsers(){
  //   this.router.navigateByUrl('/referrers');
  // }

  // allReferrals(){
  //   this.router.navigateByUrl('/unpaid');
  // }
}
