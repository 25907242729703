
import { Component, Inject, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Admin } from '../_models/admin';
import { Profile } from '../_models/profile';
import { environment } from '../../environments/environment';
import { AdminService } from '../_services/admin.service';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Component({
  selector: 'app-modal-add-admin',
  templateUrl: './modal-add-admin.component.html',
  styleUrls: ['./modal-add-admin.component.scss']
})
export class ModalAddAdminComponent implements OnInit {
  addAdminForm: FormGroup;
  isSubmitted = false;

  constructor(public dialogRef: MatDialogRef<ModalAddAdminComponent>, public service: AdminService, @Inject(MAT_DIALOG_DATA) public data: Admin, @Inject(MAT_DIALOG_DATA) public data2: Profile) { }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    this.isSubmitted = true;
    if (this.addAdminForm.invalid) {
      return;
    }

    this.service.createAdmin(this.addAdminForm.value).subscribe(() => this.dialogRef.close());
  }

  ngOnInit() {
    let email = new FormControl(this.data.email, Validators.required);
    let password = new FormControl(this.data.password, Validators.required);
    let first_name = new FormControl(this.data.first_name, Validators.required);
    let last_name = new FormControl(this.data.last_name, Validators.required);


    this.addAdminForm = new FormGroup({
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name
    });
  }
  get formControls() { return this.addAdminForm.controls; }
}
