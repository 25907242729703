import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-modal-pay-member',
  templateUrl: './modal-pay-member.component.html',
  styleUrls: ['./modal-pay-member.component.scss']
})
export class ModalPayMemberComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalPayMemberComponent>) { }

  cancel(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
  }

}
