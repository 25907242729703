import { AdminService } from '../_services/admin.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthService } from '../_authentication/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders,  HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalComponent } from '../modal/modal.component';
import { ModalPayMemberComponent } from '../modal-pay-member/modal-pay-member.component';
import { Observable, from } from 'rxjs';
import { Pagination } from '../_models/pagination';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../_models/user';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-admin-view-archive',
  templateUrl: './admin-view-archive.component.html',
  styleUrls: ['./admin-view-archive.component.scss']
})
export class AdminViewArchiveComponent implements OnInit {
  public users: Array<User> = [];
  public referrals: Array<User> = [];
  user: User;
  cookieValue = '';
  searchText: string;
  searchTextChanged: Subject<string> = new Subject<string>();

  public pagination: Pagination = {
    count: 0,
    currentPage: 1,
    perPage: 10,
    pages: 0,
    perPageOptions: [10, 25, 50],
  };

  public apiUrl = environment.apiUrl;

  constructor(private authService: AuthService, public dialog: MatDialog, private http: HttpClient, private router: Router, public service: AdminService, private cookieService: CookieService) {
    this.auth();
    this.cookieValue = this.cookieService.get('token');

    this.searchTextChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(model => {
        this.searchText = model.trim();
        this.fetchUsers();
      });
   }

  ngOnInit() {
    this.fetchUsers();
  }

  auth() {
    this.http.get(environment.apiUrl + '/api/user/session').subscribe((data: any) => {
        if (data.success) {

        } else {
          this.router.navigateByUrl('/login');
        }
      });
  }

  fetchUsers() {
    this.users = [];
    this.service.getArchivedUsers(this.pagination, this.searchText).subscribe(result => {
      this.users = result.data;
      this.parsePagination(result.meta);
    });
    // this.service.getNewestUnpaidReferrals().subscribe(result=>{this.referrals=result;})
  }
  parsePagination(meta) {
    this.pagination.currentPage = meta._page;
    this.pagination.count = meta._count;
    this.pagination.perPage = meta._perPage;
    this.pagination.pages = meta._pages;
  }

  get paginationDidChange() {
    return this.fetchUsers.bind(this);
  }

  searchChanged(text: string) {
    this.searchTextChanged.next(text);
  }

  recoverMember(user) {
    this.service.recoverMember(user).subscribe(result => this.fetchUsers());
  }

  return() {
    this.router.navigateByUrl('/admin');
  }

}
