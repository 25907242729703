import { Component, OnInit, Input } from '@angular/core';
import { Pagination } from '../../_models/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  @Input() pagination: Pagination;
  @Input() onPageChange = null;
  @Input() class = {};

  ngOnInit() {}

  public pageDidChange(newPage) {
    this.pagination.currentPage = newPage;
    this.onPageChange();
  }

}
