import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { environment } from '../../environments/environment';
import { AdminService } from '../_services/admin.service';
import { Admin } from '../_models/admin';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  isSubmitted = false;
  user: Admin;
  password: string;

  constructor(public formBuilder: FormBuilder, public service: AdminService, public dialogRef: MatDialogRef<ModalChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: Admin) { }

  cancel(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', Validators.required)
    });
  }

  get formControls() { return this.changePasswordForm.controls; }

  submit() {
    this.isSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    if (this.isSubmitted === true) {
      this.dialogRef.close(this.changePasswordForm.value);
    }
  }

}
