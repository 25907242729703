import { AdminService } from '../_services/admin.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material';
import { Observable, from } from 'rxjs';
import { Pagination } from '../_models/pagination';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Component({
  selector: 'app-admin-view-all-users',
  templateUrl: './admin-view-all-users.component.html',
  styleUrls: ['./admin-view-all-users.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminViewAllUsersComponent implements OnInit {

  public users: Array<User> = [];

  public sort: string;
  public sortOptions: Array<any> = [
    {
      Name: 'Name - A to Z',
      Key: '&_sortDir=asc&_sortField=first_name'
    },
    {
      Name: 'Name - Z to A',
      Key: '&_sortDir=desc&_sortField=first_name'
    },
    {
      Name: 'Newest',
      Key: '&_sortDir=desc&_sortField=created_at'
    },
    {
      Name: 'Oldest',
      Key: '&_sortDir=asc&_sortField=created_at'
    },
    {
      Name: 'Most Referrals',
      Key: '&_sortDir=desc&_sortField=referrals_count'
    },
    {
      Name: 'Least Referrals',
      Key: '&_sortDir=asc&_sortField=referrals_count'
    },
  ];

  public pagination: Pagination = {
      count: 0,
      currentPage: 1,
      perPage: 10,
      pages: 0,
      perPageOptions: [10, 25, 50],
  };

  constructor(private http: HttpClient, private router: Router, public service: AdminService ) {}

  ngOnInit() {
    this.sort = this.sortOptions[0].Key;
    this.fetchUsers();
  }

  fetchUsers() {
    this.users = [];
    this.service.getAllUsers(this.pagination, this.sort).subscribe(result => {
      this.users = result.data;
      this.parsePagination(result.meta);
    });
  }

  parsePagination(meta) {
    this.pagination.currentPage = meta._page;
    this.pagination.count = meta._count;
    this.pagination.perPage = meta._perPage;
    this.pagination.pages = meta._pages;
  }

  get paginationDidChange() {
    return this.fetchUsers.bind(this);
  }

  returnToDashboard() {
    this.router.navigateByUrl('/admin');
  }
}
