import { catchError, finalize, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

// https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.cookieService.get('token');

    if (token.length) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
    }

    return next.handle(request).pipe(
                catchError((error: HttpResponse<any>) => {
                    if (error.status === 401) {
                      this.router.navigateByUrl('/login');
                      return throwError(error);
                    }
                    if (error.status !== 401) {
                      return throwError('Something went wrong');
                    }
                }),
              );

  }
}
