import { ActivatedRoute, Router } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { User } from '../_models/user';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { AdminService } from '../_services/admin.service';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  createAccountForm: FormGroup;
  isSubmitted = false;
  paramFriendCode: string;
  cookieValue = 'NULL';
  message = '';
  isIE = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private route: ActivatedRoute, private cookieService: CookieService, public service: AdminService) { }

  ngOnInit() {

    this.isIE = this.detectIE() !== false;
    let first_name = new FormControl('', Validators.required);
    let last_name = new FormControl('', Validators.required);
    let email = new FormControl('', [Validators.required, CustomValidators.email]);
    let confirm_email = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);
    let phone = new FormControl('', Validators.required);
    let friend_code = new FormControl('');

    this.createAccountForm = new FormGroup({
      first_name: first_name,
      last_name: last_name,
      email: email,
      confirm_email: confirm_email,
      phone: phone,
      friend_code: friend_code,
      recaptchaToken: new FormControl(null, Validators.required)
    });

    this.route.queryParams.subscribe(params => {
      this.createAccountForm.patchValue({ friend_code: params['friend_code'] });
    });
  }

  get formControls() { return this.createAccountForm.controls; }

  detectIE() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false;
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  submit() {
    this.message = '';
    this.isSubmitted = true;
    if (this.createAccountForm.invalid) {
      return;
    }

    this.service.addReferrer(this.createAccountForm.value)
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }), catchError((error: HttpResponse<any>) => {
        this.createAccountForm.controls.recaptchaToken.reset();
        return throwError('Something went wrong');
      }), finalize(() => {
      }))
      .subscribe((data: any) => {
        if (data.code) {
          this.cookieService.set('user_code', data.code);
          this.router.navigateByUrl('/confirmation');
        } else {
          if (data.message) {
            this.message = data.message;
          } else {
            console.log(JSON.stringify(data));
          }
        }
      });
  }
}
