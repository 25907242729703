import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from '../_models/pagination';
import { User } from '../_models/user';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  public getAllUsers(pagination: Pagination, sort: string): Observable<any> {
    let url = environment.apiUrl + '/api/referral-users?&_page=' + pagination.currentPage + '&_perPage=' + pagination.perPage;
    if (!sort) {
      url += '_sortField=first_name&_sortDir=ASC';
    } else { url += sort; }
    return this.http.get<User[]>(url).pipe(map(result => result));
  }

  public getAllUnpaidReferrals(pagination: Pagination, sort: string): Observable<any> {
    let url = environment.apiUrl + '/api/referral-users?_filters={"referrer_id_is_not":"null", "_or":[{"paid":0},{"referrer_paid":0}]}&_page=' + pagination.currentPage + '&_perPage=' + pagination.perPage;
    if (!sort) {
      url += '_sortField=first_name&_sortDir=ASC';
    } else { url += sort; }
    return this.http.get<User[]>(url).pipe(map(result => result));
  }

  public getTopReferrers(pagination, searchText): Observable<any> {
      const data: any = {
        _page: pagination.currentPage,
        _perPage: pagination.perPage,
        _sortField: 'times_paid',
        _sortDir: 'desc',
      }
      if (searchText) {
        data._filters = {
          _or: {
            user_code: searchText,
            first_name_like: '%' + searchText + '%',
            last_name_like: '%' + searchText + '%',
            email_like: '%' + searchText + '%',
            phone_like: '%' + searchText + '%',
          }
        };
        if (searchText.indexOf(' ') > -1) {
          const filters = [];
          const options = searchText.split(' ');
          options.forEach((name, index) => {
            filters.push({
              _or: {
                first_name_like: name,
                last_name_like: name,
              }
            });
          });
          data._filters = {
            _and: filters
          };
        }
      }

      // URL encode filters object
      let params = new HttpParams();
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'object') {
          params = params.append(key, encodeURIComponent(JSON.stringify(data[key])));
        } else {
          params = params.append(key, data[key]);
        }
      });
      return this.http.get<any>(environment.apiUrl + '/api/referral-users', {params}).pipe(map(result => result));
  }

  public getArchivedUsers(pagination, searchText): Observable<any> {
    const data: any = {
      _page: pagination.currentPage,
      _perPage: pagination.perPage,
      _sortField: 'times_paid',
      _sortDir: 'desc',
    }
    if (searchText) {
      data._filters = {
        _or: {
          user_code: searchText,
          first_name_like: '%' + searchText + '%',
          last_name_like: '%' + searchText + '%',
          email_like: '%' + searchText + '%',
          phone_like: '%' + searchText + '%',
        }
      };
      if (searchText.indexOf(' ') > -1) {
        const filters = [];
        const options = searchText.split(' ');
        options.forEach((name, index) => {
          filters.push({
            _or: {
              first_name_like: name,
              last_name_like: name,
            }
          });
        });
        data._filters = {
          _and: filters
        };
      }
    }

    // URL encode filters object
    let params = new HttpParams();
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        params = params.append(key, encodeURIComponent(JSON.stringify(data[key])));
      } else {
        params = params.append(key, data[key]);
      }
    });
    return this.http.get<any>(environment.apiUrl + '/api/referral-users?_filters={"deleted_at_is_not":null}', {params}).pipe(map(result => result));
}

  public getAdmins(pagination, searchText): Observable<any> {
    const data: any = {
      _page: pagination.currentPage,
      _perPage: pagination.perPage,
    };
    return this.http.get<any>(environment.apiUrl + '/api/user').pipe(map(result => result));
  }

  public getArchivedAdmins(pagination, searchText): Observable<any> {
    const data: any = {
      _page: pagination.currentPage,
      _perPage: pagination.perPage,
    };
    return this.http.get<any>(environment.apiUrl + '/api/user?_filters={"deleted_at_is_not":null}').pipe(map(result => result));
  }

  public getNewestUnpaidReferrals(): Observable<User[]> {
    return this.http.get<User[]>(environment.apiUrl + '/api/referral-users?_perPage=10&_sortField=created_at&_sortDir=DESC&_filters={"referrer_id_is_not":"null", "_or":[{"paid":0},{"referrer_paid":0}]}').pipe(map(result => {return result['data']; }));
  }

  public payMember(user): Observable<User[]> {
    user.times_paid += 1;
    return this.http.put<User[]>(environment.apiUrl + '/api/referral-users/' + user.id, {times_paid: user.times_paid});
  }

  public recoverMember(user): Observable<User[]> {
    return this.http.put<User[]>(environment.apiUrl + '/api/referral-users/' + user.id, {deleted_at: null});
  }

  public recoverAdmin(user): Observable<any[]> {
    return this.http.put<any[]>(environment.apiUrl + '/api/user/' + user.id, {deleted_at: null});
  }

  public deleteMember(user): Observable<User[]> {
    return this.http.delete<User[]>(environment.apiUrl + '/api/referral-users/' + user.id);
  }

  public deleteAdmin(user): Observable<User[]> {
    return this.http.delete<User[]>(environment.apiUrl + '/api/user/' + user.id);
  }

  public payReferrer(userId): Observable<User[]> {
    return this.http.put<User[]>(environment.apiUrl + '/api/referral-users/' + userId, {referrer_paid: '1'});
  }

  public addReferrer(data): Observable<any> {
    data.phone = data.phone.replace(/\D+/g, '');
    return this.http.post(environment.apiUrl + '/api/referral-users/sign-up', data);
  }

  public createAdmin(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/user/create', data);
  }

  public editReferrer(data): Observable<any> {
    data.phone = data.phone.replace(/\D+/g, '');
    return this.http.put(environment.apiUrl + '/api/referral-users/' + data.id, data);
  }
}
