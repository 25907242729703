import { AdminService } from '../_services/admin.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Pagination } from '../_models/pagination';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-admin-view-all-unpaid-referrals',
  templateUrl: './admin-view-all-unpaid-referrals.component.html',
  styleUrls: ['./admin-view-all-unpaid-referrals.component.css']
})
export class AdminViewAllUnpaidReferralsComponent implements OnInit {
  public referrals: Array<User> = [];

  public sort: string;
  public sortOptions: Array<any> = [
    {
      "Name": "Name - A to Z",
      "Key": "&_sortDir=asc&_sortField=first_name"
    },
    {
      "Name": "Name - Z to A",
      "Key": "&_sortDir=desc&_sortField=first_name"
    },
    {
      "Name": "Newest",
      "Key": "&_sortDir=desc&_sortField=created_at"
    },
    {
      "Name": "Oldest",
      "Key": "&_sortDir=asc&_sortField=created_at"
    },
  ];

  public pagination: Pagination = {
    count: 0,
    currentPage: 1,
    perPage: 10,
    pages: 0,
    perPageOptions: [10, 25, 50],
  };

  constructor(private http: HttpClient, private router: Router, public service: AdminService ) { }

  returnToDashboard() {
    this.router.navigateByUrl('/admin');
  }

  ngOnInit() {
    this.sort = this.sortOptions[0].Key;
    this.fetchUsers();
  }

  fetchUsers() {
    this.referrals = [];
    this.service.getAllUnpaidReferrals(this.pagination, this.sort).subscribe(result => {
      this.referrals = result.data;
      this.parsePagination(result.meta);
    });
  }

  parsePagination(meta) {
    this.pagination.currentPage = meta._page;
    this.pagination.count = meta._count;
    this.pagination.perPage = meta._perPage;
    this.pagination.pages = meta._pages;
  }

  get paginationDidChange() {
    return this.fetchUsers.bind(this);
  }

  payMember(userId: string) {
    this.service.payMember(userId).subscribe(result => this.fetchUsers());
  }

  payReferrer(userId: string) {
    this.service.payReferrer(userId).subscribe(result => this.fetchUsers());
  }
}
