import { Admin } from '../_models/admin';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthService } from '../_authentication/auth.service';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  isSubmitted = false;
  message = '';
  cookieValue = 'NULL';

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private cookieService: CookieService) { }

  ngOnInit() {
      this.loginForm  =  this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required],
          recaptchaToken: new FormControl(null, Validators.required),
      });
  }

  get formControls() { return this.loginForm.controls; }

  login() {
    this.message = '';
    this.http.post(environment.apiUrl + '/api/user/login', this.loginForm.value)
    .pipe(map((response: HttpResponse<any>) => {
      return response;
  }), catchError((error: HttpResponse<any>) => {
      // console.log(error);
      if (error.status === 401) {
        this.message = 'Invalid credentials';
      }
      this.loginForm.controls.recaptchaToken.reset();
      return throwError('Something went wrong');
  }), finalize(() => {
  })).subscribe((data: any) => {
      if (data.success) {

        const minutes = 20;
        const expires = new Date((new Date()).getTime() + (minutes * 60000));

        this.cookieService.set('token', data.token, expires, '/');
        this.cookieService.set('adminLevel', data.level);
        this.router.navigateByUrl('/admin');
      } else {
        if (data.message) {
          this.message = data.message;
        } else {
          console.log(JSON.stringify(data));
        }
      }
    });
  }
}
