import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { QRCodeModule } from 'angular2-qrcode';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit, AfterViewInit {
  shareUrl = '';
  shareCode = '';
  @ViewChild('qrContainer') qrContainer;

  constructor(private router: Router, private cookieService: CookieService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.shareCode = this.route.snapshot.paramMap.get('code');
    this.shareUrl =  environment.webUrl + '/checking-account?code=' + this.route.snapshot.paramMap.get('code');
    console.log("stop");
  }

  ngAfterViewInit() {
    console.log("stop");

    const native: Element = this.qrContainer.nativeElement;

    // Array.from(this.qrContainer.nativeElement.children).filter(tag => tag.tagName === 'P')
    Array.from(native.children).filter((d) => {
      const div: Element = d;
      Array.from(div.children).filter(t => {
        const tag: Element = t;
        if (tag.tagName === 'QR-CODE') {
          const canvas = tag.children[0] as HTMLCanvasElement;

          const img = new Image();
          img.onload = () => {

              const ctx = canvas.getContext('2d');

              const canvasSize = canvas.height;
              const size = canvasSize * 0.68;

              const imgWidth = size;
              const imgHeight = size * img.height / img.width;
              const rectWidth = imgWidth * 1.05;
              const rectHeight = imgHeight * 1.5;
              const x = canvasSize / 2 - imgWidth / 2;
              const y = canvasSize / 2 - imgHeight / 2;
              const rx = canvasSize / 2 - rectWidth / 2;
              const ry = canvasSize / 2 - rectHeight / 2;

              ctx.fillStyle = 'white';
              ctx.fillRect(rx, ry, rectWidth, rectHeight);

              ctx.drawImage(img, x, y, imgWidth, imgHeight);
          };
          img.src = '/assets/svgs/logo.svg';
        }
      });
    });
  }

}
