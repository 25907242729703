import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminViewAdminsComponent } from './admin-view-admins/admin-view-admins.component';
import { AdminViewAllUnpaidReferralsComponent } from './admin-view-all-unpaid-referrals/admin-view-all-unpaid-referrals.component';
import { AdminViewAllUsersComponent } from './admin-view-all-users/admin-view-all-users.component';
import { AdminViewArchiveComponent } from './admin-view-archive/admin-view-archive.component';
import { AdminViewArchivedAdminsComponent } from './admin-view-archived-admins/admin-view-archived-admins.component';
import { AuthGuard } from './_authentication/auth.guard';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { NgModule } from '@angular/core';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { RequestReferralsComponent } from './request-referrals/request-referrals.component';
import { Routes, RouterModule } from '@angular/router';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-up'},
  { path: 'admin', component: AdminDashboardComponent},
  { path: 'admin-users', component: AdminViewAdminsComponent },
  { path: 'archived-admins', component: AdminViewArchivedAdminsComponent },
  { path: 'archived-users', component: AdminViewArchiveComponent },
  { path: 'confirmation', component: ConfirmationPageComponent },
  { path: 'login', component: AdminLoginComponent },
  { path: 'print/:code', component: PrintLayoutComponent },
  { path: 'referrers', component: AdminViewAllUsersComponent },
  { path: 'report', component: RequestReferralsComponent },
  { path: 'sign-up', component: CreateAccountComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'unpaid', component: AdminViewAllUnpaidReferralsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
