import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminService } from './_services/admin.service';
import { AdminViewAdminsComponent } from './admin-view-admins/admin-view-admins.component';
import { AdminViewAllUnpaidReferralsComponent } from './admin-view-all-unpaid-referrals/admin-view-all-unpaid-referrals.component';
import { AdminViewAllUsersComponent } from './admin-view-all-users/admin-view-all-users.component';
import { AdminViewArchiveComponent } from './admin-view-archive/admin-view-archive.component';
import { AdminViewArchivedAdminsComponent } from './admin-view-archived-admins/admin-view-archived-admins.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthHttpInterceptor } from './_authentication/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { CookieService } from 'ngx-cookie-service';
import { CreateAccountComponent } from './create-account/create-account.component';
import { FilterPipe } from './filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { IMaskModule } from 'angular-imask';
import { MaterialModule } from './material.module';
import { ModalAddAdminComponent } from './modal-add-admin/modal-add-admin.component';
import { ModalChangePasswordComponent } from './modal-change-password/modal-change-password.component';
import { ModalChangeEmailComponent } from './modal-change-email/modal-change-email.component';
import { ModalComponent } from './modal/modal.component';
import { ModalPayMemberComponent } from './modal-pay-member/modal-pay-member.component';
import { NgModule } from '@angular/core';
import { PaginationComponent } from './_shared/pagination/pagination.component';
import { PhonePipe } from './_shared/pipes/phone';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { QRCodeModule } from 'angular2-qrcode';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RequestReferralsComponent } from './request-referrals/request-referrals.component';
import { TermsComponent } from './terms/terms.component';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AdminDashboardComponent,
    AdminLoginComponent,
    AdminViewAdminsComponent,
    AdminViewAllUnpaidReferralsComponent,
    AdminViewAllUsersComponent,
    AdminViewArchiveComponent,
    AdminViewArchivedAdminsComponent,
    AppComponent,
    ConfirmationPageComponent,
    CreateAccountComponent,
    FilterPipe,
    ModalAddAdminComponent,
    ModalChangeEmailComponent,
    ModalChangePasswordComponent,
    ModalComponent,
    ModalPayMemberComponent,
    PaginationComponent,
    PhonePipe,
    PrintLayoutComponent,
    RequestReferralsComponent,
    TermsComponent,
  ],
  imports: [
    AngularSvgIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    IMaskModule,
    MaterialModule,
    QRCodeModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ],
  providers: [
    AdminService,
    CookieService,
    {  provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
   },
   {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
  },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent, ModalPayMemberComponent, ModalChangePasswordComponent, ModalChangeEmailComponent, ModalAddAdminComponent]
})
export class AppModule { }
