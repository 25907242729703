import { Component, Inject, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { environment } from '../../environments/environment';
import { AdminService } from '../_services/admin.service';
import { Admin } from '../_models/admin';

@Component({
  selector: 'app-modal-change-email',
  templateUrl: './modal-change-email.component.html',
  styleUrls: ['./modal-change-email.component.scss']
})
export class ModalChangeEmailComponent implements OnInit {
  changeEmailForm: FormGroup;
  isSubmitted = false;
  user: Admin;
  email: string;

  constructor(public formBuilder: FormBuilder, public service: AdminService, public dialogRef: MatDialogRef<ModalChangeEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: Admin) { }

  cancel(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.changeEmailForm = new FormGroup({
      email: new FormControl('', [Validators.required, CustomValidators.email])
    });
  }

  get formControls() { return this.changeEmailForm.controls; }

  submit() {
    this.isSubmitted = true;
    if (this.changeEmailForm.invalid) {
      return;
    }

    if (this.isSubmitted === true) {
      this.dialogRef.close(this.changeEmailForm.value);
    }
  }

}
