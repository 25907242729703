import { Component } from '@angular/core';

import {Router, NavigationEnd} from '@angular/router';

import { environment } from '../environments/environment';

declare var gtag;
declare let ga: (action, target, event?) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'pro0418-03-18';

  constructor(public router: Router) {

    (function (arr) {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('prepend')) {
          return;
        }
        Object.defineProperty(item, 'prepend', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function prepend() {
            var argArr = Array.prototype.slice.call(arguments),
              docFrag = document.createDocumentFragment();

            argArr.forEach(function (argItem) {
              var isNode = argItem instanceof Node;
              docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
            });

            this.insertBefore(docFrag, this.firstChild);
          }
        });
      });
    })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

    (function (arr) {
      arr.forEach(function (item) {
        if (item.hasOwnProperty('append')) {
          return;
        }
        Object.defineProperty(item, 'append', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function append() {
            var argArr = Array.prototype.slice.call(arguments),
              docFrag = document.createDocumentFragment();

            argArr.forEach(function (argItem) {
              var isNode = argItem instanceof Node;
              docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
            });

            this.appendChild(docFrag);
          }
        });
      });
    })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

    if (environment.code.indexOf('GTM') === 0) {

        // GOOGLE TAG MANAGER
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.code;
      document.head.prepend(script);

      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + environment.code;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.append(iframe);
      document.body.prepend(noscript);

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // https://fluin.io/blog/google-analytics-with-tag-manager-and-angular
          gtag('config', environment.code, { page_path : event.urlAfterRedirects});
        }
      });

      // END GOOGLE TAG MANAGER
    } else if (environment.code.indexOf('UA') === 0) {
      // GOOGLE ANALYTICS
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.google-analytics.com/analytics.js';
      script.onload = () => {
        ga('create', environment.code, 'auto');
      };
      document.head.prepend(script);

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      });
      // END GOOGLE ANALYTICS
    }
  }
}
