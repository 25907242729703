import { AngularSvgIconModule } from 'angular-svg-icon';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit {
  cookieValue = '';
  copy = false;
  socialButtons = false;
  urlPrefix = '';
  fullURL = '';

  constructor(private router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    this.cookieValue = this.cookieService.get('user_code');
    this.urlPrefix = environment.webUrl + '/checking-account?code=';
    this.fullURL = this.urlPrefix + this.cookieValue;
  }

  isiOS() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  openFacebook() {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.fullURL) + '&t=');
  }

  openTwitter() {
    window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.fullURL));
  }

  copyInputMessage(inputElement) {

    if (this.isiOS()) {
      this.iosCopyToClipboard(inputElement);
    } else {
      const el = document.getElementById(inputElement) as HTMLInputElement;
      el.select();
      document.execCommand('copy');
    }
  }

  iosCopyToClipboard(inputElement) {

    const el = document.getElementById(inputElement) as HTMLInputElement;

    const oldContentEditable = el.contentEditable;
    const oldReadOnly = el.readOnly;
    const range = document.createRange();

    el.contentEditable = 'true';
    el.readOnly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
  }

}
