import { AngularSvgIconModule } from 'angular-svg-icon';
import { Component, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-request-referrals',
  templateUrl: './request-referrals.component.html',
  styleUrls: ['./request-referrals.component.css']
})
export class RequestReferralsComponent implements OnInit {
  requestForm: FormGroup;
  isSubmitted = false;
  submit = false;
  success = false;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.requestForm  =  new FormGroup({
      emailAddress: new FormControl('', [Validators.required, CustomValidators.email]),
      recaptchaToken: new FormControl(null, Validators.required)
    });
  }

  get formControls() { return this.requestForm.controls; }

  request() {
    this.isSubmitted = true;
    if (this.requestForm.invalid) {
      return;
    }
    return this.http.get(environment.apiUrl + '/api/referral-users/count?email=' + this.requestForm.controls.emailAddress.value).subscribe
    ((data: any) => {
      if (data.data === 'success') {
        console.log('success');
        this.success = true;
        this.requestForm.reset();
        this.isSubmitted = false;
      } else {
        console.log('fail');
      }
    });
  }
}

// this.http.post(environment.apiUrl + '/api/referral-users/sign-up', this.createAccountForm.value)
// .pipe(map((response: HttpResponse<any>) => {
//   return response;
// }), catchError((error: HttpResponse<any>) => {
//   this.createAccountForm.controls.recaptchaToken.reset();
//   return throwError('Something went wrong');
// }), finalize(() => {
// }))
// .subscribe((data: any) => {
//   if (data.code) {
//     this.cookieService.set('user_code', data.code);
//     this.router.navigateByUrl('/confirmation');
//   } else {
//     if (data.message) {
//       this.message = data.message;
//     } else {
//       console.log(JSON.stringify(data));
//     }
//   }
// });
